import { useState, useEffect, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "../Elements/Link";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Header = ({ logo, logoInverse, siteName, navigation }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav
        className={`${
          isFixed ? "shadow fixed z-[9999] bg-secondary-900/90" : "bg-secondary-900"
        } top-0 left-0 w-full`}
        aria-label="Global"
      >
        <div className="container p-6">
          <div className="flex items-center justify-between">
            <div className="flex lg:flex-1">
              <Link to="home" className="cursor-pointer -m-1.5 p-1.5">
                <span className="sr-only">{siteName}</span>
                <img className="h-8" src={logoInverse} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item, itemIdx) => (
                <Link
                  key={`header-nav-item-${itemIdx}`}
                  to={item.href}
                  className="link-inverse"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>

      <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <Dialog.Panel
          focus="true"
          className="fixed inset-0 z-10 overflow-y-auto bg-secondary-900 px-6 py-6 lg:hidden"
        >
          <div className="flex items-center justify-between">
            <Link to="home" className="-m-1.5 p-1.5">
              <span className="sr-only">{siteName}</span>
              <img className="h-8" src={logoInverse} alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item, idx) => (
                  <Link
                    key={`header-mobile-nav-${idx}`}
                    to={item.href}
                    className="-mx-3 block rounded-lg py-2 px-3 link-inverse"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};
export default Header;
