import { Link as Scroll } from "react-scroll";

const Link = (props) => {
  return (
    <Scroll
      spy={true}
      smooth={true}
      duration={500}
      to={props.to}
      className="cursor-pointer"
      {...props}
    >
      {props.text ?? props.children}
    </Scroll>
  );
};
export default Link;
