import { services } from "../../data.js";
import Border from "../Elements/Border.jsx";

const Services = () => {
  return (
    <div className="section bg-gray-50">
      <div className="container">
        <div className="text-center mx-auto">
          <h2 className="section-title">Services</h2>
          <p className="section-subtitle">
            We make the <span className="text-secondary-600">Impact</span>
          </p>
          <p className="section-text mx-auto">
            UrTeam offers a variety of customized services because we know every
            customer has own needs. We are the team that will make an impact in
            your business. We listen actively to your need, we define together
            the solution, we deliver on time, and we think future-proof.
          </p>
        </div>
        <div className="mt-20">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-12 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {services.map((service, idx) => (
              <div
                key={`service-${idx}`}
                className="w-full rounded-lg px-4 py-6 border border-gray-200 bg-gray-50"
              >
                <div className="sm:flex">
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <img className="w-16" src={service.image} alt="" />
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <h3 className="content-title unde">{service.name}</h3>
                    <p className="mt-2 content-text">{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
