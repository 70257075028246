import Input from "./Input";
import Label from "./Label";
import InlineAddOn from "./InlineAddOn";

function Group({
  type = "text",
  label,
  name,
  placeholder,
  required,
  InlineLeftAddOn = false,
  InlineRightAddOn = false,
  handleChange,
}) {
  return (
    <div>
      <Label text={label} htmlFor={name} />
      <div className="mt-1 relative rounded-md shadow-sm">
        {InlineLeftAddOn && <InlineAddOn pos="left" text={InlineLeftAddOn} />}
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          required={required ? true : false}
          hasInlineRightAddOn={InlineRightAddOn && true}
          hasInlineLeftAddOn={InlineLeftAddOn && true}
          handleChange={handleChange}
        />
        {InlineRightAddOn && (
          <InlineAddOn pos="right" text={InlineRightAddOn} />
        )}
      </div>
    </div>
  );
}
export default Group;
