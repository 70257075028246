import { useState } from "react";
import Steps from "../Elements/Steps";
import { CheckIcon } from "@heroicons/react/20/solid";
import IterativeImg from "../../assets/iterative.png";

const HowWeWork = () => {
  const [current, setCurrent] = useState(1);

  const qualityDriven = [
    {
      name: "Project planning & control",
      description: "Scope, milestones, priorities, estimation, reporting.",
    },
    {
      name: "Code review",
      description: "Always done by at least two other team members.",
    },
    {
      name: "Testing levels",
      description: "Unit, integration, system and end-to-end testing.",
    },
    {
      name: "Code quality",
      description: "SOLID, Code Style, Version Control.",
    },
  ];

  const steps = [
    {
      name: "Predictable",
      href: "#",
      content: () => (
        <p className="content-text">
          Any Project has a business value, when the value is important for your
          business. You cannot relay on chance. A predictable planning, costs
          and result are a must have. Our team is experienced in project
          methodologies that guide to the aimed result within the estimated
          costs and timeline.
        </p>
      ),
    },
    {
      name: "Iterative",
      href: "#",
      content: () => (
        <>
          <img src={IterativeImg} alt="" width={600} className="mx-auto" />
        </>
      ),
    },
    {
      name: "Quality-driven",
      href: "#",
      content: () => (
        <>
          <dl className="grid grid-cols-1 gap-12 sm:grid-cols-2">
            {qualityDriven.map((qd) => (
              <div key={qd.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute mt-1 h-6 w-6 text-primary-500"
                    aria-hidden="true"
                  />
                  <p className="ml-10 content-title">{qd.name}</p>
                </dt>
                <dd className="mt-2 ml-10 content-text">{qd.description}</dd>
              </div>
            ))}
          </dl>
        </>
      ),
    },
    {
      name: "Scalable",
      href: "#",
      content: () => (
        <>
          <div className="relative">
            <dt>
              <p className="content-title">Scalable solution thinking:</p>
            </dt>
            <dd className="content-text">
              You can start small test the value of the software and scale it
              along with your business.
            </dd>
          </div>
          <div className="mt-6 relative">
            <dt>
              <p className="content-title">Scalable technology:</p>
            </dt>
            <dd className="content-text">
              Our DevOps engineers will help you build secure and scalable
              infrastructure, so your software is ready to grow with your
              business.
            </dd>
          </div>
        </>
      ),
    },
    {
      name: "Cooperative",
      href: "#",
      content: () => (
        <>
          <p className="content-text">
            We see you as a valuable team member within the project agile team.
            We work together to realise a shippable increment and deliver
            measurable value.
          </p>
          <p className="content-text">
            Transparency is one of our values. We keep our self responsible for
            it for status updates, impediments, costs and any other matter can
            can impact the progress positively or negatively.
          </p>
        </>
      ),
    },
  ];

  const currentStep = (id) => setCurrent(id);

  return (
    <>
      <div className="section bg-white">
        <div className="container">
          <div className="relative text-center">
            <h2 className="section-title sr-only">How we work ?</h2>
            <p className="section-subtitle">How we work ?</p>
          </div>

          <div className="mt-12">
            <Steps
              steps={steps}
              activeStep={current}
              currentStep={currentStep}
            />
            <div className="mt-16">
              {steps.map((step, stepIdx) => (
                <div key={`step-content-${stepIdx}`}>
                  {current === stepIdx + 1 && (
                    <div className="max-w-3xl mx-auto space-y-6">
                      <h3 className="content-title uppercase">
                        <span className="text-primary-600">{step.name}</span>
                      </h3>
                      <step.content />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HowWeWork;
