import Illustration from "../../assets/software-dev-illustration.jpg";

const Hero = ({ app }) => {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-secondary-900">
        <div className="mx-auto max-w-7xl px-6 py-20 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-lg lg:mx-0 lg:flex-auto">
            <h1 className="text-xl font-bold tracking-tight text-white sm:text-6xl">
              {<app.styledName />}
            </h1>
            <p className="mt-6 text-xl leading-10 text-gray-100 sm:text-2xl">
              {app.description}
            </p>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <img className="mx-auto max-w-full" src={Illustration} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;
