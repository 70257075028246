import { Element } from "react-scroll";
import Clients from "./Components/Sections/Clients";
import Header from "./Components/Sections/Header";
import Hero from "./Components/Sections/Hero";
import Services from "./Components/Sections/Services";
import Technologies from "./Components/Sections/Technologies";
import About from "./Components/Sections/About";
import Footer from "./Components/Sections/Footer";
import { app, Logo, LogoInverse, navigation, contacts, social } from "./data";
import Blog from "./Components/Sections/Blog";
import Contact from "./Components/Sections/Contact";
import HowWeWork from "./Components/Sections/HowWeWork";

function App() {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <Element name="home" />

        <main>
          <Header
            siteName={app.name}
            navigation={navigation}
            logo={Logo}
            logoInverse={LogoInverse}
          />
          <Hero app={app} />
          <Element name="services">
            <Services />
          </Element>
          <Element name="technologies">
            <Technologies />
          </Element>
          <Element name="about">
            <About />
          </Element>
          <Element name="howWeWork">
            <HowWeWork />
          </Element>

          {/* <Element name="blog">
            <Blog />
          </Element>
          <Clients /> */}
          <Element name="contact">
            <Contact
              app={app}
              logo={LogoInverse}
              contacts={contacts}
              social={social}
            />
          </Element>
        </main>
        <Footer
          app={app}
          navigation={navigation}
          logo={LogoInverse}
          contacts={contacts}
          social={social}
        />
      </div>
    </div>
  );
}

export default App;
