import {
  UserGroupIcon,
  LightBulbIcon,
  CursorArrowRippleIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import AboutImg from "../../assets/agency-office.jpg";
import Border from "../Elements/Border";

const About = () => {
  const team = [
    "Years of experience in software development",
    "We work as a team where the customer's need  is central",
    "Working with a development process that is continuously improved",
    "Agile in thinking and implementation",
  ];

  const features = [
    {
      name: "Team",
      description: () => (
        <ul className="list-disc space-y-2">
          {team.map((tm, tmIdx) => (
            <li key={`team-${tmIdx}`} className="ml-3">
              {tm}
            </li>
          ))}
        </ul>
      ),
      icon: UserGroupIcon,
    },
    {
      name: "Vision",
      description:
        "We create with you the right solutions that fit your needs and drive the business value you want.",
      icon: LightBulbIcon,
    },
    {
      name: "Mission",
      description:
        "The combination of the right people and the right technology delivers the aimed results.",
      icon: CursorArrowRippleIcon,
    },
    {
      name: "Values",
      description: "Passion, Leaderschip, Transparency, Agility, Ownership",
      icon: StarIcon,
    },
  ];

  return (
    <div className="bg-gray-50 section">
      <div className="container">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div>
            <div className="relative">
              <h2 className="section-title">About us</h2>
              <p className="section-subtitle">
                Building Innovative Solutions with a Personalized Touch
              </p>
            </div>
            <div className="relative mt-12">
              <dl className="space-y-10">
                {features.map((ftr, ftrIdx) => (
                  <div key={`feature-${ftrIdx}`} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-primary-500 text-white">
                        <ftr.icon className="h-8 w-8" aria-hidden="true" />
                      </div>
                      <p className="ml-16 t ext-lg font-medium leading-6 text-gray-900">
                        {ftr.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {typeof ftr.description === "string" ? (
                        ftr.description
                      ) : (
                        <ftr.description />
                      )}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <div className="relative w-[30.6rem] mx-auto">
              <img
                className="w-full aspect-square object-cover rounded-lg"
                src={AboutImg}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
