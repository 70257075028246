function InlineAddOn({ pos, text }) {
  return (
    <>
      <div
        className={`absolute inset-y-0  ${
          pos === "right" ? "right-0 pr-3" : 'left-0  pl-3'
        } pr-3 flex items-center pointer-events-none`}>
        <span className='text-neutral-500 sm:text-sm'>{text}</span>
      </div>
    </>
  );
}
export default InlineAddOn;
