import { Link } from "react-scroll";

function Button({
  link,
  text,
  type = "button",
  size,
  rounded = false,
  Icon,
  btnStyle = "primary",
  px,
  block = false,
}) {
  let sizeClasses = "";
  let iconClasses = "";

  switch (size) {
    case "xs":
      sizeClasses = `px-${px ?? "2.5"} py-1.5 text-xs`;
      iconClasses = "ml-0.5 mr-2 h-4 w-4";
      break;
    case "sm":
      sizeClasses = `px-${px ?? "3"} py-2 text-sm`;
      iconClasses = "ml-0.5 mr-2 h-4 w-4";
      break;
    case "lg":
      sizeClasses = `px-${px ?? "4"} py-2 text-base`;
      iconClasses = "-ml-1 mr-3 h-5 w-5";
      break;
    case "xl":
      sizeClasses = `px-${px ?? "6"}  py-3 text-base`;
      iconClasses = "-ml-1 mr-3 h-5 w-5";
      break;

    case "md":
    default:
      sizeClasses = `px-${px ?? "4"} py-2 text-sm`;
      iconClasses = "-ml-1 mr-2 h-5 w-5";
      break;
  }

  let style = {
    primary:
      "text-white bg-primary-500 hover:bg-primary-700 focus:bg-primary-800 transition-colors duration-200",
    secondary:
      "text-white bg-secondary-500 hover:bg-secondary-700 focus:bg-secondary-800 transition-colors duration-200",
    default:
      "border border-gray-300 text-gray-900 bg-white hover:bg-gray-100 focus:bg-gray-200 transition-colors duration-200",
  }[btnStyle ?? "default"];

  let classes = `${
    block ? "block w-full text-center" : "inline-flex items-center"
  } ${sizeClasses} border border-transparent font-medium ${
    rounded && "rounded-full"
  } shadow-sm cursor-pointer ${style}`;

  return (
    <>
      {link ? (
        <Link
          spy={true}
          smooth={true}
          duration={500}
          to={link}
          className={classes}
        >
          {Icon && <Icon className={iconClasses} />}
          {text}
        </Link>
      ) : (
        <button type={type} className={classes}>
          {Icon && <Icon className={iconClasses} />}
          {text}
        </button>
      )}
    </>
  );
}
export default Button;
