function Input({
  type = "text",
  name,
  id,
  placeholder,
  required,
  hasInlineRightAddOn = false,
  hasInlineLeftAddOn = false,
  handleChange,
}) {
  return (
    <>
      <input
        type={type}
        name={name}
        id={id ?? name}
        className={`block w-full 
        ${hasInlineRightAddOn && "pr-12"} 
        ${hasInlineLeftAddOn && "pl-7"} 
        sm:text-sm border-b border-0 border-gray-300 bg-gray-50 focus:border-b-2 focus-within:border-indigo-600 focus:border-indigo-600 focus:ring-0`}
        placeholder={placeholder}
        required={required ? true : false}
        onChange={handleChange}
      />
    </>
  );
}
export default Input;
