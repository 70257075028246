import { useState } from "react";

const Steps = ({ steps, activeStep = 1, currentStep }) => {
  const [active, setActive] = useState(activeStep);

  const handleClick = (e, id) => {
    e.preventDefault();
    currentStep(id);
    setActive(id);
  };

  return (
    <>
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
          {steps.map((step, stepIdx) => (
            <li key={`step-${stepIdx}`} className="md:flex-1">
              <a
                onClick={(e) => handleClick(e, stepIdx + 1)}
                href={step.href}
                className={`${
                  stepIdx + 1 !== active && "group"
                } flex flex-col border-l-4 
                ${
                  stepIdx + 1 > active
                    ? "border-gray-200"
                    : "border-secondary-600"
                }
                ${stepIdx + 1 < active && "hover:border-secondary-800"}
                ${stepIdx + 1 > active && "hover:border-secondary-500"}
                py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0`}
                aria-current={stepIdx + 1 === active ? "step" : ""}
              >
                <div className="flex items-center space-x-2">
                  <span
                    className={`flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-sm font-medium 
                    ${
                      stepIdx + 1 > active
                        ? "text-secondary-600 ring-1 ring-secondary-300 group-hover:text-white group-hover:bg-secondary-500 group-hover:ring-0"
                        : stepIdx + 1 < active
                        ? "text-white bg-secondary-500 group-hover:bg-secondary-700"
                        : "text-white bg-secondary-500"
                    }
                    `}
                  >
                    {stepIdx + 1}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              </a>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};
export default Steps;
