import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

import WebDevImg from "./assets/web-dev.png";
import MobileDevImg from "./assets/mobile-dev.png";
import AgileDevImg from "./assets/agile-dev.png";
import AssuranceQualityImg from "./assets/assurance-quality.png";
import Logo from "./assets/Logo.svg";
import LogoInverse from "./assets/Logo-inverse.svg";

export { Logo, LogoInverse };

export const app = {
  name: "urteam",
  shortName: "urteam",
  styledName: () => (
    <span className="text-white">
      UR<span className="text-primary-500">TEAM</span>
    </span>
  ),
  description:
    "Customised IT solutions and professional product management for successful organizations.",
};

export const navigation = [
  { name: "Home", href: "home" },
  { name: "Services", href: "services" },
  { name: "Technologies", href: "technologies" },
  { name: "About", href: "about" },
  { name: "Contact-us", href: "contact" },
];

export const services = [
  {
    name: "Web development",
    description:
      "Build or scale a competitive product ready for future growth in business and number of users.  Whether your business is interested in developing a new product, refactoring a legacy application, or advising with an existing third-party solution, we can help. For web development, we use enterprise frameworks and our team members are highly qualified developers that keep up with the technology changes.",
    image: WebDevImg,
  },
  {
    name: "Mobile development",
    description:
      "Offer your customers a fast iOS/Android hybrid mobile app their users will want to use and recommend.",
    image: MobileDevImg,
  },
  {
    name: "Agile development",
    description:
      "Work with product experts with the know - how of several industries.",
    image: AgileDevImg,
  },
  {
    name: "Quality Insurance",
    description:
      "Secure the stability and highest ratings for your product by running extensive QA tests.",
    image: AssuranceQualityImg,
  },
];

export const phone = "0621575597";

export const contacts = {
  phone: {
    title: "phone",
    description: phone,
    href: "tel:" + phone,
    icon: PhoneIcon,
  },
  email: {
    title: "email",
    description: "office@urteam.com",
    href: "mailto:office@urteam.com",
    icon: EnvelopeIcon,
  },
  address: {
    title: "address",
    description: "Veemarkt 195 1-h, 1019 CJ Amsterdam. Netherland",
    href: "#",
    icon: MapPinIcon,
  },
};

export const social = {
  facebook: {
    name: "Facebook",
    href: "https://www.facebook.com/",
    icon: (props) => (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
          d="M7 10.2222V13.7778H9.66667V20H13.2222V13.7778H15.8889L16.7778 10.2222H13.2222V8.44444C13.2222 8.2087 13.3159 7.9826 13.4826 7.81591C13.6493 7.64921 13.8754 7.55556 14.1111 7.55556H16.7778V4H14.1111C12.9324 4 11.8019 4.46825 10.9684 5.30175C10.1349 6.13524 9.66667 7.2657 9.66667 8.44444V10.2222H7Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  linkedin: {
    name: "Linkedin",
    href: "https://www.linkedin.com/in/",
    icon: (props) => (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
          d="M15.2 8.80005C16.4731 8.80005 17.694 9.30576 18.5941 10.2059C19.4943 11.1061 20 12.327 20 13.6V19.2H16.8V13.6C16.8 13.1757 16.6315 12.7687 16.3314 12.4687C16.0313 12.1686 15.6244 12 15.2 12C14.7757 12 14.3687 12.1686 14.0687 12.4687C13.7686 12.7687 13.6 13.1757 13.6 13.6V19.2H10.4V13.6C10.4 12.327 10.9057 11.1061 11.8059 10.2059C12.7061 9.30576 13.927 8.80005 15.2 8.80005Z"
          fill="currentColor"
        />
        <path d="M7.2 9.6001H4V19.2001H7.2V9.6001Z" fill="currentColor" />
        <path
          d="M5.6 7.2C6.48366 7.2 7.2 6.48366 7.2 5.6C7.2 4.71634 6.48366 4 5.6 4C4.71634 4 4 4.71634 4 5.6C4 6.48366 4.71634 7.2 5.6 7.2Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
};
