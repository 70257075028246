import { Link } from "react-scroll";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import InputGroup from "../Elements/Form/InputGroup";
import Textarea from "../Elements/Form/Textarea";
import Label from "../Elements/Form/Label";
import Button from "../Elements/Button";

const Contact = ({ app, logo, contacts, social }) => {
  return (
    <>
      <section
        className="min-h-screen bg-cover "
        style={{
          backgroundImage:
            "url('https://images.pexels.com/photos/2031706/pexels-photo-2031706.jpeg?auto=compress&cs=tinysrgb&w=1600')",
        }}
      >
        <div className="flex flex-col min-h-screen bg-black/60">
          <div className="container flex flex-col flex-1 px-6 py-12 mx-auto">
            <div className="flex-1 lg:flex lg:items-center lg:-mx-6">
              <div className="text-white lg:w-1/2 lg:mx-6">
                <h2 className="section-title">
                  <span className="text-white">What would you like to do?</span>
                </h2>

                <div className="mt-12">
                  <Link
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="home"
                    className="cursor-pointer"
                  >
                    <span className="sr-only">{app.name}</span>
                    {logo ? (
                      <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
                    ) : (
                      <span className="font-extrabold text-xl text-primary">
                        {app.name}
                      </span>
                    )}
                  </Link>
                </div>

                <ul className="mt-8 space-y-3">
                  {Object.values(contacts).map((contact) => (
                    <li
                      key={"footer-contacts-" + contact.title}
                      className="flex space-x-2"
                    >
                      <span>
                        <contact.icon className="w-5 h-5 text-white" />
                      </span>
                      <a
                        href={contact.href}
                        className="text-base text-white color-transition hover:text-primary-500"
                      >
                        {contact.description}
                      </a>
                    </li>
                  ))}
                </ul>

                <div className="mt-6 md:mt-8">
                  <h3 className="text-gray-300 ">Follow us</h3>
                  <div className="mt-3 flex space-x-6 md:order-2">
                    {Object.values(social).map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-neutral-100 color-transition hover:text-white"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-8 w-8" />
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-8 lg:w-1/2 lg:mx-6">
                <div className="w-full px-8 py-10 mx-auto overflow-hidden bg-white shadow-2xl rounded-xl lg:max-w-xl">
                  <h1 className="text-2xl font-medium text-gray-700">
                    Contact-us
                  </h1>

                  <form className="mt-6 space-y-6">
                    <InputGroup
                      label="Full Name"
                      name="fullname"
                      placeholder="John Doe"
                      required={true}
                    />
                    <InputGroup
                      type="email"
                      label="Email address"
                      name="email"
                      placeholder="johndoe@example.com"
                      required={true}
                    />

                    <div>
                      <Label text="Message" htmlFor="message" />
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <Textarea
                          name="message"
                          placeholder="Message"
                          required={true}
                        />
                      </div>
                    </div>

                    <Button
                      type="submit"
                      text="Send us a message"
                      btnStyle="primary"
                      block
                    />

                    <p className="mt-4 text-sm text-gray-400 text-center">
                      or conatct us directly at{" "}
                      <a href={contacts.email.href} className="text-gray-900">
                        {contacts.email.description}
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
