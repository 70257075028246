const Textarea = ({ rows = 4, name, id, placeholder, handleChange }) => {
  return (
    <textarea
      rows={rows}
      name={name}
      id={id ?? name}
      className="block w-full sm:text-sm border-b border-0 border-gray-300 bg-gray-50 focus:border-b-2 focus-within:border-indigo-600 focus:border-indigo-600 focus:ring-0"
      placeholder={placeholder}
      defaultValue={""}
      onChange={handleChange}
    />
  );
};
export default Textarea;
