import { Link } from "react-scroll";

const d = new Date();
let year = d.getFullYear();

function Footer({ app, logo, navigation, contacts, social }) {
  return (
    <>
      <footer className="bg-secondary-900">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 text-center">
          <p className="mt-8 text-base text-neutral-100 md:mt-0 md:order-1">
            &copy; {year}{" "}
            <Link to="/" className="link-inverse">
              <span className="font-normal">{app.name}</span>
            </Link>
            . Powered by{" "}
            <Link
              to="https://uzar.ma/"
              className="link-inverse"
              target="_blank"
              rel="noreferrer"
            >
              <span className="font-normal">UZAR</span>
            </Link>
          </p>
        </div>
      </footer>
    </>
  );
}
export default Footer;
